import React, { Component } from 'react';
import '../common.css';
import './Zones.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tooltip } from 'antd';
import gray_block from '../../images/gray_block.png';
import { zoomScale, zoomToFit, inverseZoomScale} from './utils.js';

var MAP_WIDTH = 500;
var MAP_HEIGHT = 375;
if (window.screen.width <= 420) {
    MAP_WIDTH = window.screen.width*.64;
    MAP_HEIGHT = window.screen.width*.48;
}
var MIN_ZOOM = 15;
var MAX_ZOOM = 23;
// let aspect_ratio = 1.33;
const site_id = sessionStorage.getItem("site_id");
const site_name = sessionStorage.getItem("site_name");
const floor_id = sessionStorage.getItem("floor_id");
const floor_name = sessionStorage.getItem("floor_name");
const token = sessionStorage.getItem("token");
const database = sessionStorage.getItem("database");

const pageTitle = "Manage Zones for " + site_name + ", " + floor_name;

let image_src = "";
let zone_id;

// Timestamp used for throttling scroll events
var scroll_throttle = new Date();

class Zones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracker:null,
            buildings:null,
            building_idx:null,
            floors:null,
            floor_idx:-99,
            zones:[],
            zones_by_floorplan:[[]],
            map_img:gray_block,
            zoom:1,
            width:0,
            height:0,
            direction:0,
            cam_x:0,
            cam_y:0,
            panning:false,
            placing:false,
            moving_point:false,
            pan_start_x:0,
            pan_start_y:0,
            zone_name_input:"",
            zone_color_input:"red",
            zone_point_selected:-1,
            zone_selected:-1,
            zone_selected_id:-1,
            calibrating:false,
            calibrating_msg:"Calibrate",
            devices:[],
            selected_device_MAC:"",
            selectDeviceModalIsOpen: false,
            adding_zone:false,
        };
        
        this.deletePoint = this.deletePoint.bind(this);
        this.openSelectDeviceModal = this.openSelectDeviceModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    openSelectDeviceModal() {

        this.setState({
            selectDeviceModalIsOpen: true,
        });

    }

    closeModal() {
        this.setState({
            selectDeviceModalIsOpen: false,
            device_MAC: null,
        });
    }

    componentDidMount() {
        this.getFloorplan();
        window.addEventListener('keydown', this.deletePoint);
        this.getZones();
        this.getDevices();
    }

    componentWillUnmount() {
        // console.log("UNMOUNT")
        window.removeEventListener('keydown', this.deletePoint);
        localStorage.setItem('zones', JSON.stringify(this.state.zones));
    }

    // for calibration
    getDevices = () => {
        fetch(`${database}/devices/?token=${token}`)
            .then(response => response.json())
            .then((devices) => {
                // console.log("DEVICES:", devices)
                let temp_devices = [];

                for (let i=0; i<devices.data.length; i++) {
                    temp_devices.push({
                        id:devices.data[i].id, 
                        name:devices.data[i].name,
                        MAC_address: devices.data[i].MAC_address,
                    })
                }
                this.setState({ devices: temp_devices})                
            })
            // .then(console.log("getDevices:", this.state.devices))
            .catch(err => console.error(err));
    }

    // for calibration
    logDeviceMAC = () => {
        // console.log("Selected MAC Address:",this.refs.deviceSelect.value);
        this.setState({device_MAC: this.refs.deviceSelect.value}); 
    }

    chooseDevice = (device) => {
        // console.log("Device is:", this.state.device_MAC);
        // send start calibrating request to backend server
        var url = window.location.href;
        console.log(url);
        url = url.split('https://')[1];
        // console.log(url);
        url = url.split(':')[0];
        url = "https://"+url+":1556";
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", url, true);
        let device_MAC = this.state.device_MAC;
        let zone_id = this.state.zone_selected_id;
        var params_string = "cmd_name=calibration&cmd_type=start";
        params_string += "&mac=" + device_MAC;
        params_string += "&zone_id=" + zone_id;
        xmlhttp.send(params_string);
        // console.log(url);
        // console.log(params_string);
        this.setState({
            selectDeviceModalIsOpen: false
        });
        this.calibrateZone();
    }

    getFloorplan = () => {                  
        // console.log("floor id", floor_id);
        fetch(`${database}/floors/by_floor?token=${token}&floor_id=${floor_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){
                image_src = floors.data[0].floorplan.replace(/\s/g, '');
                console.log("test");
                console.log(floors.data[0].level)
                this.setState({
                    building_idx:site_id,
                    floor_idx:floors.data[0].level,
                    floor_width: floors.data[0].width,
                    floor_height: floors.data[0].height,
                },
                () => {
                    this.loadFloorDetails();
                });
                // floor_idx = floors.data[0].level;
                // this.loadFloorDetails();
            }
            else {
                console.log("Did not find floor with floor_id:", floor_id);
            }
        }).catch(function(err) {
            console.log(err);
        });      
    }

    // load floor data and update state for rendering
    loadFloorDetails = () => {
        console.log("test1")
        console.log(this.state.floor_idx)
        let floor_idx = this.state.floor_idx;
        if (floor_idx === -99) {
            console.log("In loadFloorDetails(), floor index is unexpectedly unfilled");
            return;
        }

        let that = this;
        // Update state to have building details
        let zoom = zoomToFit(this.state.floor_width, this.state.floor_height, MAP_WIDTH, MAP_HEIGHT);
        console.log("this.state.floor_width:", this.state.floor_width);
        console.log("this.state.floor_height:", this.state.floor_height);
        console.log("MAP_WIDTH:", MAP_WIDTH);
        console.log("MAP_HEIGHT:", MAP_HEIGHT);
        let width = this.state.floor_width;
        let height = this.state.floor_height;

        let direction = 0;
	MIN_ZOOM = Math.round(zoom);
	MAX_ZOOM = Math.round(zoom)+5;


        that.setState({
            map_img:image_src,
            zoom:zoom,
            width:width,
            height:height,
            direction:direction,
            cam_x:0,
            cam_y:0,
            floor_idx:floor_idx,
        });
    }

    mapMouseDown = (event) => {
        event.preventDefault();
        this.setState({
            pan_start_x:event.pageX,
            pan_start_y:event.pageY,
            panning:true,
            placing:true,
        })
    }

    mapMouseMove = (event) => {
        event.preventDefault();
        if (this.state.moving_point && this.state.zone_point_selected !== -1) {
            // let node = ReactDom.findDOMNode(this);
            let container = document.getElementById("map");
            let cont_rect = container.getBoundingClientRect();
            let height = zoomScale(this.state.height, this.state.zoom);
            let xOffset = 0;
            let yOffset = -1*window.pageYOffset;
            let point_x = event.pageX-cont_rect.left + xOffset;
            let point_y = height-(event.pageY-cont_rect.top + yOffset);
            if (this.state.zone_selected !== -1) {
                let zones = this.state.zones;
                let zone_selected = this.state.zone_selected;
                let point = {
                    x:inverseZoomScale(point_x, this.state.zoom),
                    y:inverseZoomScale(point_y, this.state.zoom),
                }
                zones[zone_selected].polygon[this.state.zone_point_selected] = point;
                this.setState({zones:zones, placing:false});
            }
        }
        else if (this.state.panning) {
            // Get mouse movement delta's and apply them to camera positions
            let new_x = this.state.cam_x + (event.pageX - this.state.pan_start_x);
            let new_y = this.state.cam_y + (event.pageY - this.state.pan_start_y);
            this.setState({
                cam_x:new_x,
                cam_y:new_y,
                pan_start_x:event.pageX,
                pan_start_y:event.pageY,
                placing:false,
            })
        }
    }

    mapMouseWheel = (event) => {
        event.preventDefault();
        let curr_time = new Date();
        // Zoom event throttled to 50 milliseconds
        if (curr_time.getTime() - scroll_throttle.getTime() > 50) {
            scroll_throttle = curr_time;
            let new_zoom = this.state.zoom;
            let new_x = this.state.cam_x;
            let new_y = this.state.cam_y;
            
            // Zoom IN
            if (event.deltaY < 0) {
                new_zoom = Math.min(MAX_ZOOM, new_zoom+0.25);
            }
            // Zoom OUT
            else {
                new_zoom = Math.max(MIN_ZOOM, new_zoom-0.25);
            }

            // Shift Camera positions so that focal point remains the same
            if (new_x !== 0) {
                new_x = new_x*zoomScale(new_x, new_zoom)/zoomScale(new_x, this.state.zoom);
            }
            if (new_y !== 0) {
                new_y = new_y*zoomScale(new_y, new_zoom)/zoomScale(new_y, this.state.zoom);
            }
            
            this.setState({zoom:new_zoom, cam_x:new_x, cam_y:new_y})
        }
    }

    mouseUp = (event) => {
        if (this.state.placing === true && this.state.moving_point === false) {
            // let node = ReactDom.findDOMNode(this);
            let container = document.getElementById("map");
            let cont_rect = container.getBoundingClientRect();
            let height = zoomScale(this.state.height, this.state.zoom);
            let xOffset = 0;
            let yOffset = -1*window.pageYOffset;
            let point_x = event.pageX-cont_rect.left +xOffset;
            let point_y = height-(event.pageY-cont_rect.top + yOffset);
            if (this.state.zone_selected !== -1) {
                let zones = this.state.zones;
                let zone_selected = this.state.zone_selected;
                let point = {
                    x:inverseZoomScale(point_x, this.state.zoom),
                    y:inverseZoomScale(point_y, this.state.zoom),
                }
                zones[zone_selected].polygon.push(point);
                this.setState({panning:false, placing:false, zones:zones});
            }
            else {
                this.setState({panning:false, placing:false});
            }
        }
        else {
            this.setState({panning:false, placing:false, moving_point:false});
        }
    }

    getZones = () => {
        let that = this;
        // get all zones in sql db for this floor
        fetch(`${database}/zones/by_floor?token=${token}&floor_id=${floor_id}`)
            .then(response => response.json())
            .then((zones) => {
                // console.log("getZones() messages");
                // console.group();
                if (zones.data.length > 0) {
                    // console.log("Retrieved zones", zones.data);
                    let zones_array = [];
                    for (let i=0; i<zones.data.length; i++) {
                        let polygon = JSON.parse(zones.data[i].polygon);
                        let safety_text = "Alert";
                        if (zones.data[i].safety_level === "3") {
                            safety_text = "Safe";
                        }
                        else if (zones.data[i].safety_level === "2") {
                            safety_text = "Warn";
                        }
                        let zone = {
                            id: zones.data[i].id,
                            name: zones.data[i].name,
                            color: zones.data[i].color,
                            safety_level: zones.data[i].safety_level,
                            safety_text: safety_text,
                            polygon: polygon,
                        }
                        // console.log("zone:", zone);
                        // console.log("polygon data:", polygon);
                        zones_array.push(zone);                        
                    }
                    // console.log("zones array:", zones_array);
                    // sort newest zones to top of table
                    zones_array = zones_array.sort(function(a,b){return b.id - a.id});
                    that.setState({zones:zones_array});
                }
                else {
                    console.log("floor", floor_id, "has no zones");
                    that.setState({zones:[]});
                }
                // console.groupEnd();
            })
            .catch(err => console.error(err));    
    }

    addZone = () => {
        let zones = this.state.zones
        let entry = {
            id:9999,
            name:"New Zone",
            color:"red",
            safety_level:"",
            polygon:[],
        }
        
        zones.push(entry);
        // sort newest to top
        zones = zones.sort(function(a,b){return b.id - a.id});
        this.setState({
            zones:zones,
            adding_zone:true,
        });
    }

    deleteZone = () => {
        let zone_id = this.state.zone_selected_id;
        // console.log("ZONE ID::", zone_id);
        if (window.confirm("Are you sure you want to delete this zone?")){
            fetch(`${database}/zones/delete?token=${token}&id=${zone_id}`)
                .then(this.getZones())
                .catch(err => console.error(err)); 
        }
        this.resetZoneSelect();
    }

    deletePoint = (event) => {
        let zones = this.state.zones;
        let zone_selected = this.state.zone_selected;
        let zone_point_selected = this.state.zone_point_selected;
        // console.log(event.key);
        if ((event.key === "Backspace" || event.key === "Delete") && zone_point_selected !== -1) {
            zones[zone_selected].polygon.splice(zone_point_selected, 1);
            this.setState({zones:zones, zone_point_selected:-1});
        }
    }

    editZone = () => {
        let zones = this.state.zones;
        let zone_selected = this.state.zone_selected;
        let zone_selected_id = this.state.zone_selected_id;
        let name = this.state.zone_name_input;
        let color = this.state.zone_color_input;
        let safety_level = this.state.zone_safety_level;
        if ( safety_level === "" ) {
            safety_level = "1"
        }

        // console.log("editZone polygon:", zones[zone_selected].polygon);
        
        // convert polygon array of points to JSON string
        let polygon = JSON.stringify(zones[zone_selected].polygon);
        
        this.setState({zones:zones, zone_selected:-1});

        if (zone_selected_id === -1 || zone_selected_id === 9999) {

            // console.log("ADDING ZONE")

            // store new zone in sql database
            fetch(`${database}/zones/add?token=${token}&name=${name}&color=${color}&safety_level=${safety_level}&polygon=${polygon}&site_id=${site_id}&floor_id=${floor_id}`)
                .then(this.getZones())
                .then(this.resetZoneSelect())
                .catch(err => console.log(err));

        }
        else {
            // update existing zone in sql database
            // console.log("UPDATING ZONE");
            fetch(`${database}/zones/edit?token=${token}&id=${zone_selected_id}&name=${name}&color=${color}&safety_level=${safety_level}&polygon=${polygon}&site_id=${site_id}&floor_id=${floor_id}`)
                .then(this.deleteGrids())
                .then(this.getZones) 
                .catch(err => console.err(err));

            
        }
    }

    deleteGrids = () => {
        zone_id = this.zone_id;
        // when updating, polygon points may have changed.  delete grids
        console.log("Zone", this.zone_id, "updated - deleting grids");
        fetch(`${database}/zones/delete_grids?token=${token}&zone_id=${zone_id}`, {method:'DELETE'})
            // .then(console.log("Zone ID is:", this.zone_id))
            .catch( err => console.log(err));
    }

    resetZoneSelect = () => {
        // console.log("Reset selected zone values in state")
        this.setState({ 
            zone_selected:-1,
            // zone_selected_id:-1, 
            zone_name_input:"",
            zone_color_input:"",
            zone_safety_level:"",
            calibrating:false,
            calibrating_msg:"Calibrate",
            selected_device_MAC:"",
            adding_zone: false,
        });
        this.getZones();
    }

    stopCalibration = () => {

        // console.log("stop calibrating");
        // send stop calibrating request to backend server
        if (window.confirm("Are you sure you want to stop calibrating this zone?")) {
            var url = window.location.href;
            url = url.split("https://")[1];
            url = url.split(':')[0];
            url = "https://"+url+":1556";
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open("POST", url, true);
            let device_MAC = this.state.device_MAC;
            // let zone_id = this.state.zone_selected_id;
            var params_string = "cmd_name=calibration&cmd_type=stop";
            params_string += "&mac=" + device_MAC;
            xmlhttp.send(params_string);

            this.setState({
                calibrating:!this.state.calibrating,       
                calibrating_msg:"Calibrate",
            });
            return;
        }

    }

    calibrateZone = () => {
        // console.log("calibrateZone()");
        // console.log("calibrating:", this.state.calibrating);

        if (!this.state.calibrating) {
            // console.log("start calibrating");

            this.setState({
                calibrating:!this.state.calibrating,
                calibrating_msg:"Stop",
                // selectDeviceModalIsOpen: true,
            });
        }


        let zone_id = this.state.zones[this.state.zone_selected].id;
        let zone = this.state.zones[this.state.zone_selected];
        // console.log("Calibrate Zone", this.state.zone_name_input, "db index:", zone_id);
        // console.log("Zone:", zone);

        // create grids for this zone if they don't already exist.  They will only exist if 
        // calibration has been run and the zone wasn't updated in the meantime.
        fetch(`${database}/zones/grids_by_zone?token=${token}&zone_id=${zone_id}`)
        .then(response => response.json())
        .then((grids) => {

            if (grids.data.length > 0) {
                // gridsFound = true;
                // console.log("Using grids from db");
            } else {
                // console.log("No grids found for this zone, adding new")
                if (window.confirm("This zone does not have previous calibration data.  It could take several minutes to grid this zone.")) {
                    let xArray = [];
                    let yArray = [];
                    for (let i = 0; i < zone.polygon.length; i++) {
                        xArray.push(zone.polygon[i].x);
                        yArray.push(zone.polygon[i].y);
                    }
                    // console.log("x array",xArray);
                    // console.log("y array",yArray);
                    let left = Math.floor(Math.min(...xArray));
                    let right = Math.ceil(Math.max(...xArray));
                    let top = Math.ceil(Math.max(...yArray));
                    let bottom = Math.ceil(Math.min(...yArray));
                    // console.log("left:", left);
                    // console.log("right:", right);
                    // console.log("top:", top);
                    // console.log("bottom:", bottom);
                    
                    for (let x=left; x<=right; x++) {
                        for (let y=bottom; y<=top; y++) { 
                            let grid = {x:x, y:y};
                            //store grid in sql database
                            fetch(`${database}/zones/add_grid?token=${token}&zone_id=${zone_id}&x=${x}&y=${y}`, {method:'POST'})
                            .then(function(results) {
                                console.log("Adding grid:", grid);
                            })
                            .catch(err => console.log("Error storing grid:",err));
                        }
                    }
                }
            } 
        })
        .catch(err => {
            console.error("Error looking up grids:",err);
        });

    }

    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>45){
            alert("Zone Name cannot exceed 45 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Zone name cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            zone_name_input:e.target.value,
            edit_changes_made: true,
        });  
    }

    logSafetyLevel = () => {
        let safetyLevel = this.refs.safetySelect.value;
        let color;
        if (safetyLevel === "1") {
            color = "red";            
        }
        else if (safetyLevel === "2") {
            color = "orange";
        }
        else {
            color = "green";
        }

        // console.log("Safety Level:", safetyLevel, "color:", color);
        this.setState({
            zone_safety_level: safetyLevel,
            zone_color_input: color,
        })
    }

    // This is needed for firefox, otherwise there are 3 distinct events when
    // selecting from a dropdown. This causes race conditions, use this to disable
    // the unneeded events.
    stopEventPropagation = (event) => {
	    event.stopPropagation();
    }

    render() {
        let that = this;
        let width = zoomScale(this.state.width, this.state.zoom);
        // console.log("width:", width);
        // console.log("state width:", this.state.width);
        // console.log("state zoom:", this.state.zoom);
        let height = zoomScale(this.state.height, this.state.zoom);

        // let tracker = this.state.tracker;
        let zoom = this.state.zoom;
        let cam_x = this.state.cam_x;
        let cam_y = this.state.cam_y;
        let back_text = "Return to Floors List";

        return (
            <div id="zone-container" onMouseUp={this.mouseUp}>

                <Header />

                <div className="page-title">{pageTitle}</div> 

                <div className="zones-page-wrapper">

                    <div id="map-row">
                        <div 
                            id="zone-map-wrapper"
                            style={{width:MAP_WIDTH, height:MAP_HEIGHT, position: "relative",margin:"auto", overflow:"hidden"}}
                        >
                            <img
                                id="map"
                                style={{ 
                                    position:"absolute",
                                    transform:'rotate('+this.state.direction+"deg)",
                                    left:MAP_WIDTH/2-width/2 + cam_x,
                                    top:MAP_HEIGHT/2-height/2 + cam_y
                                }}
                            
                                height={height}
                                width={width}
                                src={"data:image/bmp;base64,"+image_src}
                                alt=""
                            />
                            <svg
                                onMouseDown={this.mapMouseDown}
                                onMouseMove={this.mapMouseMove}
                                onWheel={this.mapMouseWheel}
                                style={{position:"absolute", width:MAP_WIDTH, height:MAP_HEIGHT, left:0, top:0}}
                            >
                                <g id="map-bounds" transform={"translate("+(MAP_WIDTH/2-width/2+cam_x)+","+(MAP_HEIGHT/2+height/2+cam_y)+")"}>

                                    {this.state.zones && this.state.zones.map((zone, index) => {
                                        let points = "";
                                        var idx;
                                        for (idx in (zone.polygon)) {
                                            let point = zone.polygon[idx];
                                            points += (zoomScale(point.x, zoom)) + "," + (-1*zoomScale(point.y, zoom)) + " ";
                                        }
                                        //console.log("map polygon", index, "points:", points);
                                        return (
                                        <g className="zone-bounds" key={zone.id}>
                                        <polygon 
                                            points={points} 
                                            style={{
                                                stroke:index === this.state.zone_selected?this.state.zone_color_input:zone.color, 
                                                fill:index === this.state.zone_selected?this.state.zone_color_input:zone.color,
                                                opacity:.3
                                            }}/>
                                        <polygon 
                                            points={points} 
                                            style={{
                                                stroke:index === this.state.zone_selected?this.state.zone_color_input:zone.color, 
                                                strokeWidth:2, 
                                                strokeOpacity:1, 
                                                fill:'none'
                                            }}/>
                                        {index === this.state.zone_selected && zone.polygon.map((point,index) => {
                                            let cx = zoomScale(point.x, zoom);
                                            let cy = -1*zoomScale(point.y, zoom);
                                            let stroke = index===that.state.zone_point_selected?"black":"none";
                                            return (
                                                <circle onMouseDown={()=>{that.setState({zone_point_selected:index, moving_point:true})}}
                                                    cx={cx} cy={cy}
                                                    r="5"
                                                    style={{
                                                        fill:"black", 
                                                        stroke:stroke, 
                                                        strokeWidth:"3px" 
                                                    }}
                                                />
                                            )
                                        })}
                                        </g>
                                        )
                                    })}
                    
                                </g>
                            </svg>
                        </div>
                    </div>
                    
                    <div id="zone-controls-row">

                        <div className="top-button-row">
                            <div className="table-label">Zone List</div>
                            <Tooltip placement="top" title="Add Zone">
                                <div 
                                    className="add-btn" 
                                    onClick={!this.state.adding_zone&&this.addZone}
                                    style={{cursor:this.state.adding_zone?"wait":"pointer"}}
                                />
                            </Tooltip>
                        </div>
                        
                        <div id="zone-table-header">
                            <div id="zone-select-label" className="zone-table-label"></div>
                            <div id="zone-name-label" className="zone-table-label">NAME</div>
                            <div id="zone-safety-label" className="zone-table-label">SAFETY</div>
                            <div id="zone-action-label" className="zone-table-label">ACTION</div>                     
                        </div>
                        <div id="zone-table-wrapper">
                            <table>
                                <tbody>

                                {this.state.zones && this.state.zones.map((zone, index)=>{
                                
                                if (this.state.zone_selected === index) {
                                    
                                    return (
                                    <tr className="selected-row" key={zone.id}>
                                        <td>
                                            <div className="zone-selected" 
                                                onClick={()=>{that.resetZoneSelect()}}
                                                style={{backgroundColor:this.state.zone_color_input}}
                                            
                                            />
                                        </td>

                                        <td>
                                            <input type="text"
                                                value={this.state.zone_name_input}
                                                onChange={this.logChangeName} 
                                            />
                                        </td>
                                        <td>
                                            <select name="safety_level" 
                                                id="safety_level"
                                                ref="safetySelect"
                                                onMouseUp={this.stopEventPropagation}
                                                onMouseDown={this.stopEventPropagation}
                                                value={this.state.zone_safety_level}
                                                onChange={(event) => {this.logSafetyLevel()}}
                                            >
                                                <option disabled="disabled" selected>Select</option>
                                                <option value="1">Alert</option>
                                                <option value="2">Warning</option>
                                                <option value="3">Safe</option>
                                            
                                            </select>
                                        </td>
                                        <td className="selected-zone-actions">

                                            <Tooltip placement="top" title="Cancel">
                                                <button 
                                                    className="zone-edit-cancel-btn selected-zone-btn" 
                                                    onClick={()=>{that.resetZoneSelect()}}
                                                    style={{marginLeft:this.state.zone_selected_id===9999&&"29px"}}
                                                />
                                            </Tooltip>
                                            
                                            <Tooltip placement="top" title="Delete Zone">
                                                <button 
                                                    className="zone-edit-delete-btn selected-zone-btn"
                                                    onClick={this.deleteZone} 
                                                    disabled={this.state.zone_selected===-1}
                                                    style={{display:this.state.zone_selected_id===9999?"none":"block"}}
                                                />
                                            </Tooltip>
                                            
                                            <Tooltip placement="top" title="Submit">
                                                <button
                                                    className="zone-edit-submit-btn selected-zone-btn"
                                                    onClick={this.editZone}
                                                />
                                            </Tooltip>

                                        </td>
                                    </tr> 
                                    )

                                }

                                    return (
                                    <tr className="unselected-row" key={zone.id}>
                                        <td>
                                            <div className="zone-select" 
                                                style={{borderColor:zone.color}}
                                                onClick={() => {
                                                    that.setState({
                                                        zone_selected:index,
                                                        zone_selected_id:zone.id?zone.id:-1, 
                                                        zone_name_input:zone.name,
                                                        zone_color_input:zone.color,
                                                        zone_safety_level:zone.safety_level,
                                                    });
                                                    if (zone.id) {
                                                        that.zone_id = zone.id
                                                    }
                                                    else {
                                                        that.zone_id = -1
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div className="zone-name">{zone.name}</div>
                                        </td>
                                        <td>
                                            <div 
                                                className="zone-safety-level"
                                                style={{color:zone.color, fontWeight:"500"}}
                                            >{zone.safety_text}</div>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    )
                                    
                                })}
                                
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="return-button-wrapper">
                        <button 
                            className="setup-back-btn" 
                            id="back-to-floors"
                            onClick={() => {
                                window.location.href="/floors";
                            }}
                        >{back_text}</button>
                    </div>
                                        
                </div>

                <Footer />
            </div>
        );
    }
}

export default Zones;
