import React, { Component } from 'react';
import '../common.css';
import './Floors.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { callbackify } from 'util';
import { Tooltip } from 'antd';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

const site_id = sessionStorage.getItem("site_id");
const site_name = sessionStorage.getItem("site_name");
const database = sessionStorage.getItem("database");

// console.log("floor site id is:", site_id);

class Floors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // siteHeaderLabel: "Manage Floors for " + props.site.name,
            siteHeaderLabel1: "Manage Floors for "+site_name,
            siteHeaderLabel2: site_name,
            siteName: "",
            siteId: site_id,
            floors: [],
            floorName: "",
            floorLevel: "",
            floorHeight: "",
            floorWidth: "",
            floorplanData: "",
            floorplan: "",
            file_size: 0,
            mime_type: "",
            currentFloor: "",
            currentFloorName: "",
            adding_floor: false,
            editing_floor: false,
            edit_changes_made: false,
        };
    

        this.addFloor = this.addFloor.bind(this);
        this.editFloor = this.editFloor.bind(this);
        this.viewEditFloor = this.viewEditFloor.bind(this);
        this.viewAddFloor = this.viewAddFloor.bind(this);
        this.previewFile = this.previewFile.bind(this);
    }
    
    viewEditFloor(floor) {
        // edit is temporarily disabled
        // console.log("Floor:", floor);
        this.site_id = floor.site_id;
        let preview = "data:image/png;base64,"+floor.floorplan;
        this.setState({
            editing_floor: true,
            edit_changes_made: false,
            floorName:floor.name,
            floorLevel:floor.level,
            siteId:floor.site_id,
            floorplan:floor.floorplan,
            floorplanData:floor.floorplan,
            preview: preview,
            mime_type:floor.mime_type,
            file_size:floor.file_size,
            floorHeight:floor.height,
            floorWidth:floor.width,
            floorId:floor.id,
        });
        // console.log("floorId:", floor.id);
    }
    
    afterOpenEditFloorModal() {
    // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }
        
    viewAddFloor(floor) {
        this.setState({
            adding_floor: true,
            currentFloor:floor.id,
        }); 
    }
    
    afterOpenAddFloorModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    addEditComplete = () => {
        this.setState({
            editing_floor: false,
            adding_floor: false,
            edit_changes_made: false,
            floorHeight:"",
            floorId:"",
            floorLevel:"",
            floorName:"",
            floorWidth:"",
            floorplan:"",
            floorplanData:"",
            file_size:"",
        });
    }
    
    closeModal() {
        if (this.state.addModalIsOpen) {
            if(this.state.floorName||this.state.floorLevel||this.state.floorHeight||this.state.floorWidth||this.state.floorplanData) {
                if(window.confirm("You have made changes to this form.  Do you want to close without saving?")) {
                    this.setState({
                        editFloorModalIsOpen: false,
                        addFloorModalIsOpen: false,
                        edit_changes_made: false,
                        floorHeight:"",
                        floorId:"",
                        floorLevel:"",
                        floorName:"",
                        floorWidth:"",
                        floorplan:"",
                        floorplanData:"",
                        file_size:"",
                    });
                }
            }
        }
        else if (this.state.editModalIsOpen) {
            if (!this.state.edit_changes_made) {
                if (window.confirm("You have made changes to this floor.  Do you want to close without saving?")) {
                    this.setState({
                        editFloorModalIsOpen: false,
                        addFloorModalIsOpen: false,
                        edit_changes_made: false,
                        floorHeight:"",
                        floorId:"",
                        floorLevel:"",
                        floorName:"",
                        floorWidth:"",
                        floorplan:"",
                        floorplanData:"",
                        file_size:"",
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.getFloors();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getFloors = () => {                  

        // console.log("site id", site_id);
        fetch(`${database}/floors/by_site?token=${token}&site_id=${site_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){
                // console.log("site", site_id, "has", floors.data.length, "floors");                       
                let floors_array = [];
                for (let j=0; j<floors.data.length; j++) {
                    let floor = {
                        id:floors.data[j].id,
                        name:floors.data[j].name,
                        site_id:floors.data[j].site_id,
                        level:floors.data[j].level,
                        floorplan:floors.data[j].floorplan,
                        mime_type:floors.data[j].mime_type,
                        file_size:floors.data[j].file_size,
                        height:floors.data[j].height,
                        width:floors.data[j].width,
                    }
                    floors_array.push(floor);
                }
                // console.log("floors array:", floors_array);
                this.setState({floors:floors_array});
            }
            else {
                console.log("site", site_id, "has no floors");
            }
        }).catch(function(err) {
            console.log(err);
        });      
    }

    addFloor() {
        if (!this.state.floorName) {
            alert("Please enter a name for the floor.");
            return;
        }
        else if (!this.state.floorLevel) {
            alert("Please enter a level for the floor.");
            return;
        }
        else if (!this.state.floorHeight || !this.state.floorWidth) {
            alert("Please enter aspect ratio dimenstions for the floor.");
            return;
        }
        else if (!this.state.floorplanData) {
            alert("Please choose an image file for the floorplan.");
            return;
        }
        else {
            console.log("Edit Floor data is complete");
        }

        let data = {
            name: this.state.floorName,
            site: this.state.siteId,
            level: parseInt(this.state.floorLevel, 10),
            floorplanData: this.state.floorplanData,
            mime_type: "image/bmp",
            file_size: this.state.file_size, 
            floorHeight: this.state.floorHeight,
            floorWidth: this.state.floorWidth,
        };

        // console.log("add floor data:", data);
      
        fetch(`${database}/floors/add?token=${token}&name=${data.name}&site_id=${data.site}&level=${data.level}&mime_type=${data.mime_type}&file_size=${data.file_size}&height=${data.floorHeight}&width=${data.floorWidth}`, {method:'POST', headers: {'Accept':'application/json', 'Content-Type': "application/json"}, body:JSON.stringify({image:this.state.floorplanData})})
            .then(this.getFloors)
            .catch(err => console.error(err));

        this.setState({
            currentSite:"", 
            floorName:"", 
            floorLevel:"", 
            floorplanData: "",
            floorplan:"", 
            mime_type:"",
            file_size:0,
            floorHeight:"", 
            floorWidth:""});
        this.addEditComplete();
    }
        
    editFloor() {
        // console.log("EDIT SITE ID IS::", this.site_id);

        if (!this.state.floorName) {
            alert("Please enter a name for the floor.");
            return;
        }
        else if (!this.state.floorLevel) {
            alert("Please enter a level for the floor.");
            return;
        }
        else if (!this.state.floorHeight || !this.state.floorWidth) {
            alert("Please enter aspect ratio dimenstions for the floor.");
            return;
        }
        else if (!this.state.floorplanData) {
            alert("Please choose an image file for the floorplan.");
            return;
        }
        else {
            console.log("Edit Floor data is complete");
        }

        let editFloorplan = "";
        if (this.state.floorplanData !== this.state.floorplan) {
            editFloorplan = this.state.floorplanData;
            // console.log("Using new floorplan");
        }
        else if (this.state.floorplan) {
            editFloorplan = this.state.floorplan;
            // console.log("Using original floorplan");
        }
        else {
            console.log("No floorplan");
        }
        let data = {
            id: parseInt(this.state.floorId, 10),
            name: this.state.floorName,
            site_id: this.site_id,
            level: parseInt(this.state.floorLevel, 10),
            floorplan: editFloorplan,
            mime_type: "image/bmp",
            file_size: 0,
            height: parseInt(this.state.floorHeight, 10),
            width: parseInt(this.state.floorWidth, 10),
        }

        // console.log("edit floor data:", data);
        // update database
        fetch(`${database}/floors/edit?token=${token}&id=${data.id}&name=${data.name}&site_id=${data.site_id}&level=${data.level}&mime_type=${data.mime_type}&file_size=${data.file_size}&height=${data.height}&width=${data.width}`, {method:'POST', headers: {'Accept':'application/json', 'Content-Type': "application/json"}, body:JSON.stringify({image:editFloorplan})})
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function(data) { 
            // console.log("Edit Floor Response Data::", data);
            this.getFloors();
        }).catch(function(err) {
            console.log(err);
        });

        this.getFloors();
        this.addEditComplete();
        this.setState({
            floorName: "",
            level: "",
            floorplanData: "",
            floorplan: "",
            mime_type: "",
            file_size: 0,
            floorHeight: "",
            floorWidth: "",
            id: "",
        })
    }
    
    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>30){
            alert("Floor Name cannot exceed 30 characters");
            return;
        }
        if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Floor name cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            floorName: e.target.value,
            edit_changes_made: true,
        });  
        // console.log(this.state.floorName);
    }

    logChangeLevel = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const floorLevel = (e.target.validity.valid) ? e.target.value : this.state.floorLevel;
        this.setState({ 
            floorLevel: floorLevel,
            edit_changes_made: true,
         });  
    }

    logChangeHeight = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const floorHeight = (e.target.validity.valid) ? e.target.value : this.state.floorHeight;
        this.setState({ 
            floorHeight: floorHeight,
            edit_changes_made: true,
        });  
    }

    logChangeWidth = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const floorWidth = (e.target.validity.valid) ? e.target.value : this.state.floorWidth;
        this.setState({ 
            floorWidth: floorWidth,
            edit_changes_made: true,
         });  
    }

    previewFile = () => {
        let that = this;
        var preview = document.getElementById("floorplan-preview");
        var file    = document.querySelector('input[type=file]').files[0];
        var reader  = new FileReader();
        var data = ""
        
        reader.addEventListener("load", function () {
            // console.log("reader result:", reader.result);  
            preview.src = reader.result;       
            data = reader.result.split(',')[1];
            that.setState({floorplanData: data});
        }, false);
        
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    render() {
        const {floors} = this.state;
        let site = site_id;   
        let back_text = "Return to Site List"; 
        return (
            <div id="floors-container">

                <Header/>

                <div className="page-title">{this.state.adding_floor||this.state.editing_floor?this.state.siteHeaderLabel2:this.state.siteHeaderLabel1}</div>
                <div className='content-container'>
                {!this.state.adding_floor&&!this.state.editing_floor&&
                <div id='floors-wrapper'>

                    <div className="top-button-row">
                        <div className="table-label">Floor List</div>
                        <Tooltip placement="top" title="Add Floor">
                            <div className="add-btn" onClick={this.viewAddFloor} />
                        </Tooltip>
                    </div>

                    {this.state.floors && this.state.floors.length > 0 &&
                    <table id="floor-table">
                        <tbody>
                            {floors.map(floor => 
                            <tr className="floor-row" key={floor.id}>
                                <td className="main-col">{floor.name}</td>
                                <td className="action-wrapper action-wrapper-3">

                                    <Tooltip placement="top" title="Edit Floor">
                                        <div className="table-btn edit-btn" onClick={() => this.viewEditFloor(floor)} />
                                    </Tooltip>
                                    
                                    <div className="manage-next-btn"
                                        onClick={()=>{
                                            sessionStorage.setItem("floor_id", floor.id);
                                            sessionStorage.setItem("floor_name", floor.name);
                                            window.location.href="/zones";
                                        }}
                                    >ZONES</div>

                                    <Tooltip placement="top" title="Delete Floor">
                                        <div className="table-btn delete-btn"                   
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to delete this floor? This will also remove its zones.")){
                                                    fetch(`${database}/floors/delete?token=${token}&id=${floor.id}`)
                                                    .then(this.getFloors)
                                                    .catch(err => console.error(err))
                                                }
                                            }}
                                        />
                                    </Tooltip>

                                </td>

                            </tr>
                            )}

                        </tbody>
                    </table>
                    }

                    <button 
                        className="setup-back-btn" 
                        id="back-to-sites"
                        onClick={() => {
                            window.location.href="/sites";
                        }}
                    >{back_text}</button>

                </div>
                }

                {this.state.adding_floor&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Add Floor</div>
                        <div className="disabled-add-btn"/>
                    </div>
                    <div className="add-edit-form">

                        <div className="floor-inputs-1">
                            <div className="form-input-label">Name</div>
                            <div className="form-input-label">Level</div>
                            <input
                                className="form-control floor-name" 
                                onChange={this.logChangeName} 
                                placeholder="Name"
                                type='text'
                                name="floorName"
                                value={this.state.floorName}
                            />
                            <input
                                className="form-control floor-level" 
                                onInput={this.logChangeLevel} 
                                placeholder="Level"
                                type='text'
                                pattern="[0-9]*"
                                name="floorLevel"
                                value={this.state.floorLevel}
                            />
                        </div>

                        <div className="floor-label-wrapper-2">
                            <div className="form-input-label" style={{fontSize:"14pt"}}>Floorplan Aspect Ratio</div>
                        </div>
                        <div className="floor-label-wrapper-3">
                            <div className="form-input-label">Height</div>
                            <div className="form-input-label">Width</div>
                        </div>
                        <div className="floor-inputs-2">
                            <input
                                className="form-control floor-height" 
                                onInput={this.logChangeHeight} 
                                placeholder="Height"
                                type='text'
                                pattern="[0-9]*"
                                name="floorHeight"
                                value={this.state.floorHeight}
                                
                            />
                            <div className="slash-divider">{" / "}</div>
                            <input
                                className="form-control floor-width" 
                                onInput={this.logChangeWidth} 
                                placeholder="Width"
                                type='text'
                                pattern="[0-9]*"
                                name="floorWidth"
                                value={this.state.floorWidth}
                            />
                            <input
                                id="add-file"
                                type="file"
                                name="add-file"
                                accept=".png, .jpg, .bmp" 
                                ref={input => {this.fileInput = input}} 
                                onChange={this.previewFile}
                                style={{border:"none"}}
                            />                                    
                            <button className="floorplan-upload-btn">
                                <label 
                                    id="floorplan-select-text"
                                    htmlFor="add-file"
                                >Choose File</label>
                            </button>

                        </div>

                        <div className="img-container">
                            <img 
                                id="floorplan-preview" 
                                src="" 
                                alt="Floorplan preview..." 
                                style={{
                                    display: this.fileInput?"block":"none",
                                }}
                            />
                        </div>

                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.addFloor}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>

                    </div>
                </div>
                }

                {this.state.editing_floor&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Edit Floor</div>
                        <div className="disabled-edit-btn"/>
                    </div>
                    <div className="add-edit-form">

                        <div className="floor-inputs-1">
                        <div className="form-input-label">Name</div>
                            <div className="form-input-label">Level</div>
                            <input
                                className="form-control floor-name" 
                                onChange={this.logChangeName} 
                                placeholder={this.state.floorName}
                                type='text'
                                name="floorName"
                                value={this.state.floorName}
                            />

                            <input
                                className="form-control floor-level" 
                                onInput={this.logChangeLevel} 
                                placeholder={this.state.floorLevel}
                                type='text'
                                pattern="[0-9]*"
                                name="floorLevel"
                                value={this.state.floorLevel}
                            />
                        </div>

                        <div className="floor-label-wrapper-2">
                            <div className="form-input-label" style={{fontSize:"14pt"}}>Floorplan Aspect Ratio</div>
                        </div>
                        <div className="floor-label-wrapper-3">
                            <div className="form-input-label">Height</div>
                            <div className="form-input-label">Width</div>
                        </div>
                        <div className="floor-inputs-2">
                            <input
                                className="form-control floor-height" 
                                onInput={this.logChangeHeight} 
                                placeholder={this.state.floorHeight}
                                type='text'
                                pattern="[0-9]*"
                                name="floorHeight"
                                value={this.state.floorHeight}
                                
                            />
                            <div className="slash-divider">{" / "}</div>
                            <input
                                className="form-control floor-width" 
                                onInput={this.logChangeWidth} 
                                placeholder={this.state.floorWidth}
                                type='text'
                                pattern="[0-9]*"
                                name="floorWidth"
                                value={this.state.floorWidth}
                            />
                            <input
                                id="add-file"
                                type="file"
                                name="add-file"
                                accept=".png, .jpg, .bmp" 
                                ref={input => {this.fileInput = input}} 
                                onChange={this.previewFile}
                                style={{border:"none"}}
                            />                                    
                            <button className="floorplan-upload-btn">
                                <label 
                                    id="floorplan-select-text"
                                    htmlFor="add-file"
                                >Choose File</label>
                            </button>

                        </div>

                        <div className="img-container">
                            <img 
                                id="floorplan-preview" 
                                src={this.state.preview}
                                alt="Floorplan preview..." 
                                style={{
                                    display: this.fileInput?"block":"none",
                                }}
                            />
                        </div>

                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.editFloor}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>

                    </div>
                </div>
                }  
                </div>
                <Footer/>

            </div>
        );
    }
}

export default Floors;
