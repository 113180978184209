import React, { Component } from 'react';
import '../common.css';
import './Patients.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tooltip } from 'antd';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

const database = sessionStorage.getItem("database");

class Patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            sites:[],
            roles:[],
            devices:[],
            patient: {
                name: "",
                alias: "",
                site_id: "",
                device_id: "",                
            },
            name: "",
            alias: "",
            pid: "",
            site_id: "",
            chart: "",
            editing_site: false,
            device_id: "",
            id: "",
            adding_patient: false,
            editing_patient: false,
            addModalIsOpen: false,
            editModalIsOpen: false,
            edit_changes_made: false,
        };
    
        this.addPatient = this.addPatient.bind(this);
        this.editPatient = this.editPatient.bind(this);
        this.viewEditPatient = this.viewEditPatient.bind(this);       
        this.viewAddPatient = this.viewAddPatient.bind(this);
    }

    viewEditPatient(patient) {
        this.setState({
            adding_patient: false,
            editing_patient: true,
            name:patient.name,
            alias:patient.alias,
            pid:patient.pid,
            site_id:patient.site_id,
            editing_site: false,
            edit_changes_made: false,
            id:patient.id,
        });
    }
    
    viewAddPatient() {
        this.setState({
            adding_patient: true,
            editing_patient: false,
        });
    }

    addEditComplete = () => {
        this.setState({
            editing_patient: false,
            edit_changes_made: false,
            adding_patient: false,
            name:"",
            alias:"",
            pid: "",
            site_id:"",
            editing_site: false,
            device_id:"",
            id:"",
        });
    }

    componentDidMount() {
        this.getSites();
        this.getDevices();
        this.getPatients(); 
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getPatients = () => {
        fetch(`${database}/patients/?token=${token}`)
            .then(response => response.json())
            .then(patients => this.setState({ patients: patients.data}))
            .then(console.log("getPatients:", this.state.patients))
            .catch(err => console.error(err));        
    }
    
    getSites = () => {
        fetch(`${database}/sites/?token=${token}`)
            .then(response => response.json())
            .then(sites => this.setState({ sites: sites.data }))
            .then(console.log("getSites:", this.state.sites))
            .catch(err => console.error(err));        
    }    

    getDevices = () => {
        fetch(`${database}/devices/?token=${token}`)
            .then(response => response.json())
            .then(devices => this.setState({ devices: devices.data }))
            .then(console.log("getDevices:", this.state.devices))
            .catch(err => console.error(err));
    }
    
    addPatient() {
        for (let i=0; i<this.state.patients.length; i++) {
            if (this.state.patients[i].alias===this.state.alias) {
                alert("This alias is already in use. Please enter a unique alias for this patient.");
                return;
            }
        }
        if (!this.state.alias) {
            alert("Please enter an alias for this patient.");
            return;
        }
        else if (!this.state.pid) {
            alert("Please enter the participant ID for this patient.");
            return;
        }
        else if (!this.state.site_id) {
            alert("Please select a site for this patient.");
            return;
        }

        let data = {
            chart: this.state.chart,
            name: this.state.name,
            pid: this.state.pid,
            alias: this.state.alias,
            site_id: parseInt(this.state.site_id),
        }
        console.log("ADD DATA:", JSON.stringify(data));
        fetch(`${database}/patients/add?token=${token}&name=${data.name}&chart=${data.chart}&alias=${data.alias}&pid=${data.pid}&site_id=${data.site_id}`)
            .then(this.getPatients)
            .catch(err => console.error(err));
        this.setState({
            patient: {name:"", alias:"", site_id:""},
            editing_site: false,
            edit_changes_made: false,
            edit_alias_change: false,
            edit_pid_change: false,
            edit_name_change: false,
        });
        this.addEditComplete();
    }

    editPatient() {
        if (this.state.edit_alias_change) {
            for (let i=0; i<this.state.patients.length; i++) {
                if (this.state.patients[i].alias===this.state.alias) {
                    alert("This alias is already in use. Please enter a unique alias for this patient.");
                    return;
                }
            }
        }
        if (!this.state.alias) {
            alert("Patient alias is required.  Please enter a patient alias.")
        }
        if (!this.state.site_id) {
            alert("Please select a site for this patient.");
            return;
        }

        let data = {
            chart: this.state.chart,
            name: this.state.name,
            alias: this.state.alias,
            pid: this.state.pid,
            site_id: parseInt(this.state.site_id),
            id: this.state.id,
        }
        console.log("data:", data);
        fetch(`${database}/patients/edit?token=${token}&id=${data.id}&chart=${data.chart}&name=${data.name}&alias=${data.alias}&pid=${data.pid}&site_id=${data.site_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function(data) {
            console.log(data);
            this.getPatients();
        }).catch(function(err) {
            console.log(err);
        });

        if (this.state.editing_site) {
            // spin thru devices
            let devices = this.state.devices;
            let editDevice = null;
            for (let i=0; i<devices.length; i++) {
                if (devices[i].patient_id === this.state.id) {
                    // is any device assigned to this patient?
                    editDevice = devices[i].id;
                }
            }
            // if so, edit device else nothing
            if (editDevice) {
                if(window.confirm("You have changed this patient's site. Activlink recommends that you change the site for this patient's site at the same time. Do you want to change the site for the device as well?")) {
                    let id = editDevice;
                    let site_id = this.state.site_id;
                    fetch(`${database}/devices/edit_site?token=${token}&id=${id}&site_id=${site_id}`)
                    .then( response => {
                        if (response.status >= 400) {
                            throw new Error("Bad response from server");
                        }
                        return response.json();
                    })
                    .catch(function(err) {
                        console.log(err);
                    });
                }
                else {
                    console.log("User chose to not update device site.");
                }
            }
            else {
                console.log("No device assigned to this patient.");
            }          
        }

        this.setState({
            patient: {id:"", name:"", alias:"", site_id:""},
            editing_site: false,
            edit_changes_made: false,
            edit_alias_change: false,
            edit_name_change: false,
        });
        this.getPatients();
        this.addEditComplete();
    } 

    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);        
        let validString = this.nothingSpecial(e.target.value);
        if (validString||e.target.value.length===0) {
            this.setState({
                name: e.target.value.length>0?e.target.value:"",
                edit_name_change: true,
                edit_changes_made: true,
            }); 
        }
        else {
            alert("Name can only be letters and numbers or blank.");
        }
    }  

    logChangePid = (e) => {
        // console.log("PID",e.target.value);
        // if PID turns out to be a GUID, then use validateGUID to validate
        this.setState({
            pid: e.target.value,
            edit_pid_change: true,
            edit_changes_made: true,
        });  
    } 

    logChangeAlias = (e) => {
        // console.log("event",e.target.value, e.target.value);
        let validString = this.nothingSpecial(e.target.value);
        if (validString || e.target.value==="") {
            this.setState({
                alias: e.target.value,
                edit_alias_change: true,
                edit_changes_made: true,
            });  
        }
        else {
            alert("Alias can only be letters and numbers.")
        }
    } 

    nothingSpecial = (string) => {
        let regex = /^$|^[a-zA-Z0-9\_\-\s]+$/;        
        return regex.test(string);
    }

    validateHEX = (string) => {
        let regex = /^$|^[a-fA-F0-9\-\s]+$/;        
        return regex.test(string);
    }
    
    logSiteId = () => {
        // console.log("Site ID:",this.refs.siteSelect.value);
        this.setState({
            site_id: this.refs.siteSelect.value,
            editing_site: true,
            edit_changes_made: true,
        });  
    }

    render() {
        const {patients, sites, devices} = this.state;
	    let result = null;
        return (
            <div id="patient-container">

                <Header/>

                <div className="page-title">Manage Patients</div>
                <div className='content-container'>
                {!this.state.adding_patient&&!this.state.editing_patient&&
                <div id="patients-wrapper">
                    <div className="top-button-row">
                        <div className="table-label">Patient List</div>
                        <Tooltip placement="top" title="Add Patient">
                            <div className="add-btn" onClick={this.viewAddPatient} />
                        </Tooltip>
                    </div>
                    
                    <table id="patient-table">
                        <thead>
                            <tr>
                                <td className="patient-alias">Alias</td>
                                <td className="patient-site">Site</td>
                            </tr>
                        </thead>
                        <tbody>
                        {patients.map(Patient => 
                            <tr key={Patient.id} id="patient-row">
                                <td className="primary-col">{Patient.alias}</td>
                                <td className="secondary-col">{(result = this.state.sites.find((x) => {return x.id === Patient.site_id}))?result.name:"None"}</td>       
                                <td className="action-wrapper action-wrapper-2">

                                    <Tooltip placement="top" title="Edit Patient">
                                        <div className="table-btn edit-btn"
                                            onClick={() => this.viewEditPatient(Patient)}
                                            style={{marginLeft:"auto"}}
                                        />
                                    </Tooltip>
                                    
                                    <Tooltip placement="top" title="Delete Patient">
                                        <div className="table-btn delete-btn" 
                                            onClick={() => {
                                                if(window.confirm("Are you sure you want to remove this patient?")){
                                                    fetch(`${database}/patients/delete?token=${token}&id=${Patient.id}`)
                                                    .then(this.getPatients)
                                                    .catch(err => console.error(err))
                                                }    
                                            }}
                                        />
                                    </Tooltip>

                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                </div>                
                }

                {this.state.adding_patient&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Add Patient</div>
                        <div className="disabled-add-btn"/>
                    </div>
                    <div className="add-edit-form">
                        <div className="form-input-label">Participant ID Number</div>
                        <input
                            className="form-control patient-pid-input" 
                            onChange={this.logChangePid} 
                            placeholder="Enter participant ID (required)"
                            type='text'
                            name="pid"
                            value={this.state.pid}
                        />                         
                        <div className="form-input-label">Patient Alias</div>
                        <input
                            className="form-control patient-alias-input" 
                            onChange={this.logChangeAlias} 
                            placeholder="Enter patient alias (required)"
                            type='text'
                            name="alias"
                            value={this.state.alias}
                        /> 
                        <div className="form-input-label">Site</div>
                        <select 
                            ref="siteSelect" 
                            name="site_select" 
                            className="patient-site-select" 
                            onChange={(e) => {this.logSiteId()}} 
                            defaultValue={'DEFAULT'}
                        >
                            <option disabled value="DEFAULT">Choose a Site</option>
                            {sites.map(Site => {
                                if (Site.name !== 'All Sites') {
                                    return <option key={Site.id} value={Site.id}>{Site.name}</option>
                                }
                            })}
                        </select>
                        <div className="cs-btn-group">
                            <button className="cs-btn submit-btn"onClick={this.addPatient}>Submit</button>
                            <button className="cs-btn cancel-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>
                    </div>
                </div>
                }

                {this.state.editing_patient&&
                    <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Edit Patient</div>
                        <div className="disabled-edit-btn"/>
                    </div>
                    <div className="add-edit-form">
                        <div className="form-input-label">Participant ID Number cannot be changed</div>
                        <input
                            className="form-control patient-pid-input" 
                            onChange={this.logChangePid} 
                            placeholder={this.state.pid}
                            type='text'
                            name="pid"
                            value={this.state.pid}
                            disabled
                        />  
                        <div className="form-input-label">Patient Alias</div>
                        <input
                            className="form-control patient-alias-input" 
                            onChange={this.logChangeAlias} 
                            placeholder={this.state.alias}
                            type='text'
                            name="alias"
                            value={this.state.alias}
                        /> 
                        <div className="form-input-label">Site</div>
                        <select 
                            ref="siteSelect" 
                            name="site_select" 
                            id="site-select" 
                            onChange={(e) => {this.logSiteId()}} 
                            defaultValue={this.state.site_id}
                        >
                            <option disabled value="DEFAULT">Choose a Site</option>
                            {sites.map(Site => {
                                if (Site.name !== 'All Sites') {
                                    return <option key={Site.id} value={Site.id}>{Site.name}</option>
                                }
                            })}
                        </select>
                        <div className="cs-btn-group">
                            <button className="cs-btn submit-btn"onClick={this.editPatient}>Submit</button>
                            <button className="cs-btn cancel-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>
                    </div>
                </div>
                }
                </div>
                <Footer/>

            </div>
        );
    }
}

export default Patients;
